import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { toast } from 'react-toastify'

import './AdminCommunities.scss'

const AdminCommunities = ({ api }) => {
    const [communities, setCommunities] = useState([])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Last Modified',
            dataIndex: 'lastModifiedAt',
            key: 'lastModifiedAt',
        },
        {
            title: 'Action',
            key: 'id',
            dataIndex: 'id',
            render: id => {
                const community = communities.find(community => community.id === id)
                return (
                    <>
                        <Link class="button is-info" to={`/community/${id}/feed`}><i class="fas fa-eye"></i>View</Link>
                        <Link class="button is-success" to={`/community/${id}/admin`}><i class="fas fa-edit"></i>Edit</Link>
                        { community.deleted ? <button class="button is-success" onClick={() => { changeCommunityStatus(id, false) }}><i class="fas fa-check-circle"></i>Reactivate</button> :
                            <button class="button is-danger" onClick={() => { changeCommunityStatus(id, true) }}><i class="fas fa-trash"></i>Delete</button>}
                    </>
                )
            },
        },
    ];

    async function getCommunities() {
        const showDeleted = true
        const communities = await api.getCommunities(showDeleted);
        setCommunities(communities)
    }

    async function changeCommunityStatus(communityId, shouldDelete) {
        const confirmed = window.confirm(`Are you sure you want to ${shouldDelete ? 'deactivate' : 'reactivate'} this community?`)
        if (confirmed) {
            const { error, ...result } = await api.updateCommunity(communityId, { deleted: shouldDelete })
            if (error) {
                toast.error(`Error ${shouldDelete ? 'deactivating' : 'reactivating'} community ${error}`)
            }
            else {
                await getCommunities()
            }
        }
    }

    useEffect(() => {
        getCommunities()
    }, [])

    async function createCommunity() {
        const name = window.prompt("Please choose a new name for the new community")

        try {
            if (name) {
                const { error, ...result } = await api.createCommunity({ name })
                if (error) {
                    toast.error(error)
                }
                else {
                    await getCommunities()
                }
            }
        }
        catch (error) {
            toast.error(error)
        }
    }

    return (
        <section class="communitiesContainer">
            <h1 class="title is-1">Communities</h1>
            <a class="button is-info" onClick={createCommunity}><i class="fas fa-plus" />New Community</a>
            <Table columns={columns} dataSource={communities} />
        </section>
    )
}

export default AdminCommunities;