import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './PaymentCanceled.scss'

const PaymentCanceled = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <div class="payment-canceled-container">
            <div class="cancel-card fadeInDown">

                <div class="card-title">
                    <i class="fas fa-exclamation" style={{ maxHeight: "3.75rem" }} />
                    <p>{t('payment.canceled.info')}</p>

                    <button>
                        <Link class="log-in fadeInDown" to="/community/1">{t('payment.canceled.cta')}</Link>
                    </button>
                </div>

            </div>
        </div >
    )
}



export default PaymentCanceled