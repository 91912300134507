import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Input from 'react-phone-number-input/input'
import Navbar from '../../components/Navbar'
import { useInputChange } from '../../hooks'

import './UserSettings.scss'

const ListInput = ({ title, entries, name, onChange }) => {
    const { t } = useTranslation()
    const [listEntries, setListEntries] = useState(entries)

    function addNewEntry() {
        const response = window.prompt('Enter a new entry');
        if (response) listEntries.push(response)
        setListEntries(listEntries)
        onChange({ currentTarget: { name, value: listEntries } })
    }

    function editEntry(e) {
        listEntries[e.target.name] = e.target.value
        setListEntries(listEntries)
        onChange({ currentTarget: { name, value: listEntries } })
    }

    function deleteEntry(index) {
        listEntries.splice(index, 1)
        setListEntries(listEntries)
        onChange({ currentTarget: { name, value: listEntries } })
    }

    return (
        <div class="box" style={{ width: 'max-content' }}>
            <div class="field">
                <label class="label" style={{ textDecoration: 'underline' }}>{title}
                    <button class="button is-small" style={{ marginLeft: '5px' }} onClick={addNewEntry}><i class="fas fa-pencil-alt" /></button>
                </label>
                <ul style={{ height: '200px', listStyleType: 'disc' }}>
                    {
                        listEntries.length > 0 ? listEntries.map((entry, i) => (
                            <div class="field is-grouped">
                                <p class="control">
                                    <input class="input" type="text" name={`${i}`} defaultValue={entry} onChange={editEntry} />
                                </p>
                                <p class="control" onClick={() => { deleteEntry(i) }}>
                                    <button class="button">
                                        <span class="icon is-small">
                                            <i class="fas fa-trash" />
                                        </span>
                                    </button>
                                </p>
                            </div>
                        )) : <p>{t('user_settings.no_items_found')}</p>
                    }
                </ul>
            </div>
        </div>

    );
}

const UserSettings = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [state, setState] = useState({})
    const [input, handleInputChange] = useInputChange()
    const [error, setError] = useState(null)
    const { t } = useTranslation()

    const onPhoneNumberChange = (value) => {
        input.phoneNumber = value
    }

    const handlePicChange = (e) => {
        const { files } = e.target
        if (files[0]) {
            input.avatar = files[0]
            const fr = new FileReader();
            fr.onload = function () {
                const updatedUser = state.user
                updatedUser.profile.avatar = fr.result
                setState({ ...state, user: updatedUser })
            }
            fr.readAsDataURL(files[0]);
        }
    }

    useEffect(() => {
        async function getProfile() {
            const user = await props.api.me()
            setState({ ...state, user })
            setIsLoading(false)
        }

        getProfile()
    }, [])

    const redirectToCustomerPortal = async () => {
        setIsLoading(true)
        try {
            const returnURL = window.location.href
            const { error, ...result } = await props.api.createCustomerPortalSession(state.user.user_id, returnURL)

            if (error) {
                setError(error)
            }
            else {
                window.location.href = result.session.url;
                setError(null)
            }
        }
        catch (err) {
            console.error(err)
            setError(err.message)
        }
        setIsLoading(false)
    }

    const saveProfile = async () => {
        setIsLoading(true)
        try {
            const { error, ...result } = await props.api.updateUser(state.user.user_id, input)
            if (error) {
                setError(error)
            }
            else {
                setError(null)
            }
        }
        catch (err) {
            console.error(err)
            setError(err.message)
        }
        setIsLoading(false)
    }

    const deleteAccount = async () => {
        const accepted = window.confirm("Are you sure you want to delete your account?")
        if (accepted) {
            setIsLoading(true)
            try {
                const { error, ...result } = await props.api.deleteAccount(state.user.user_id)
                if (error) {
                    setError(error)
                }
                else {
                    setError(null)
                    window.location.replace("/logout")
                }
            }
            catch(err){
                console.error(err)
                setError(err.message)
            }
            setIsLoading(false)
        }
    }

    const { user } = state
    return (
        <>
            {
                user &&
                <>
                    <Navbar api={props.api} user={user} />
                    <div class="settings-container">
                        <fieldset disabled={isLoading}>
                            <div class="columns">
                                <div class="column is-2">
                                    <div class="field">
                                        <label id="pictureLabel" class="label">{t('user_settings.profile_picture')}</label>
                                        <Avatar shape="circle" size={window.screen.width * 0.15} src={user.profile.avatar} icon={<UserOutlined />} />
                                        <div class="file has-name is-left">
                                            <label class="file-label">
                                                <input class="file-input" type="file" name="avatar" onChange={handlePicChange} />
                                                <span class="file-cta">
                                                    <span class="file-icon">
                                                        <i class="fas fa-upload"></i>
                                                    </span>
                                                    <span class="file-label">
                                                        {t('user_settings.image_upload_cta')}
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="container">
                                        <div class="field">
                                            <label class="label">{t('user_settings.phone_number')}</label>
                                            <div class="control">
                                                <Input class="input" country="US" value={user.profile.phoneNumber} onChange={onPhoneNumberChange} />
                                            </div>
                                        </div>

                                        <div class="field">
                                            <label class="label">{t('user_settings.birthdate')}</label>
                                            <div class="control">
                                                <input class="input" defaultValue={user.profile.birthdate} name="birthdate" type="date" onChange={handleInputChange} />
                                            </div>
                                        </div>

                                        <div class="field">
                                            <label class="label">{t('user_settings.profession')}</label>
                                            <div class="control">
                                                <input class="input" defaultValue={user.profile.profession} name="profession" type="text" onChange={handleInputChange} />
                                            </div>
                                        </div>

                                        <div class="field">
                                            <label class="label">{t('user_settings.job_anniversary')}</label>
                                            <div class="control">
                                                <input class="input" defaultValue={user.profile.jobAnniversary} name="jobAnniversary" type="date" onChange={handleInputChange} />
                                            </div>
                                        </div>

                                        <div class="field">
                                            <label class="label">{t('user_settings.billing_title')}</label>
                                            <div class="control">
                                                <button class="button is-info" onClick={redirectToCustomerPortal}>{t('user_settings.billing_cta')}</button>
                                            </div>
                                        </div>

                                        {/* <div class="field">
                                            <label class="label">{t('user_settings.baptism_date')}</label>
                                            <div class="control">
                                                <input class="input" defaultValue={user.profile.baptismDate} name="baptismDate" type="date" onChange={handleInputChange} />
                                            </div>
                                        </div> */}

                                        {/* <div class="field">
                                            <label class="label">{t('user_settings.sabbath_school_class')}</label>
                                            <div class="control">
                                                <input class="input" defaultValue={user.profile.sabbathSchoolClass} name="sabbathSchoolClass" type="text" onChange={handleInputChange} />
                                            </div>
                                        </div> */}

                                        {error && <p class="has-text-danger">{error}</p>}

                                        <div class="field is-grouped" style={{ marginTop: '1rem' }}>
                                            <div class="control">
                                                <button class="button is-success" onClick={saveProfile}>{t('user_settings.save')}</button>
                                            </div>
                                            <div class="control">
                                                <button class="button is-link is-light">{t('user_settings.cancel')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    {/* <div class="field">
                                        <label class="label">{t('user_settings.willing_to_have_study_groups')}</label>
                                        <div class="control">
                                            <div class="select">
                                                <select defaultValue={user.profile.willingToHaveStudyGroups} name="willingToHaveStudyGroups" onChange={handleInputChange}>
                                                    <option value={"Yes"}>{t('user_settings.willing_to_have_study_groups_yes')}</option>
                                                    <option value={"No"}>{t('user_settings.willing_to_have_study_groups_no')}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                    <ListInput title={t('user_settings.hobbies')} entries={user.profile.hobbies || []} name="hobbies" onChange={handleInputChange} />
                                    <ListInput title={t('user_settings.provided_services')} entries={user.profile.providedServices || []} name="providedServices" onChange={handleInputChange} />
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-2"></div>
                                <div class="column">
                                    <section style={{ marginTop: '9rem' }}>
                                        <h1 class="title is-4">{t('user_settings.delete_account_cta')}</h1>
                                        <h2 class="subtitle">
                                            {t('user_settings.delete_account_info')}
                                        </h2>
                                        <button class="button is-link is-danger" onClick={deleteAccount}>{t('user_settings.delete_account_cta')}</button>    
                                    </section>
                                </div>
                                <div class="column"></div>
                            </div>
                            
                        </fieldset>
                    </div>
                </>
            }
        </>
    )
}

export default UserSettings