import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { useInputChange } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import "react-datepicker/dist/react-datepicker.css";

const ViewEventModal = ({ isOpen, toggle, event, canEdit, updateEvent, deleteEvent }) => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [input, handleInputChange] = useInputChange(event)
    const { t } = useTranslation()

    const recurrenceOptions = ["once", "daily", "weekly", "monthly", "yearly"]

    useEffect(() => {
        if (event.start) setStartDate(new Date(event.start))
        if (event.end) setEndDate(new Date(event.end))
    }, [event.start, event.end])
    
    const handleStartDateChange = (date) => {
        setStartDate(date)
        input.start = date
    }

    const handleEndDateChange = (date) => {
        setEndDate(date)
        input.end = date
    }

    return (
        <div class={`modal ${isOpen && 'is-active'}`}>
            <div class="modal-background" onClick={() => toggle(!isOpen)}></div>
            <div class="modal-card fadeInDown" style={{ animationDuration: '1s' }}>
                <header class="modal-card-head">
                    <p class="modal-card-title"><i class="far fa-calendar-alt"></i>{t('calendar.modal.title')}</p>
                    <button class="delete" aria-label="close" onClick={() => toggle(!isOpen)}></button>
                </header>

                <section class="modal-card-body">
                    <fieldset disabled={false}>
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">{t('calendar.modal.start_date')}</label>
                                    <p class="control is-expanded">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleStartDateChange}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            showTimeSelect
                                            disabled={!canEdit}
                                            timeFormat="hh:mm a"
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            className="input" />                                        
                                    </p>
                                </div>
                                <div class="field">
                                    <label class="label">{t('calendar.modal.end_date')}</label>
                                    <p class="control is-expanded">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={handleEndDateChange}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            showTimeSelect
                                            disabled={!canEdit}
                                            timeFormat="hh:mm a"
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            className="input" />   
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <label class="label">{t('calendar.modal.how_often')}</label>
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select disabled={!canEdit} name="recurrence" onChange={handleInputChange}>
                                            { recurrenceOptions.map( value => {
                                                return <option value={value} selected={event.recurrence === value}>{value.charAt(0).toUpperCase() + value.slice(1)}</option>       
                                            })}
                                        </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">{t('calendar.modal.event_title')}</label>
                                    <div class="control">
                                        <input class="input" type="text" defaultValue={event.title} readOnly={!canEdit} placeholder="Daily Meeting" name="title" onChange={handleInputChange} />
                                    </div>
                                </div>

                                <div class="field">
                                    <label class="label">{t('calendar.modal.event_location')}</label>
                                    <div class="control">
                                        <input class="input" type="text" defaultValue={event.location} readOnly={!canEdit} name="location" onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">{t('calendar.modal.event_description')}</label>

                                    <div class="control">
                                        <textarea class="textarea" defaultValue={event.description} readOnly={!canEdit} name="description" onChange={handleInputChange}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </section>

                <footer class="modal-card-foot">
                    { canEdit && <button class="button is-success" onClick={() => updateEvent(event.id, input)}>{t('calendar.modal.cta')}</button> }
                    <button class="button" onClick={() => toggle(!isOpen)}>{t('calendar.modal.cancel')}</button>
                    { canEdit && <button class="button is-danger" onClick={() => deleteEvent(event.id)} style={{ marginLeft: 'auto' }}>{t('calendar.modal.delete')}</button> }
                </footer>
            </div>
        </div>
    );
}

export default ViewEventModal