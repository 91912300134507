import React from 'react'

import '../../index.scss'

const Loader = () => (
    <div class="sk-folding-cube" style={{ marginTop: '25%' }}>
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
)

export default Loader