import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCanceled from './components/PaymentCanceled';

const Payment = (props) => {
    return (
        <>
            {/* <Navbar api={props.api} user={state.user} /> */}
            <Switch>
                <Route exact path="/payment/success" component={() => <PaymentSuccess />} />
                <Route exact path="/payment/canceled" component={() => <PaymentCanceled />} />
            </Switch>
        </>
    )
}

export default Payment