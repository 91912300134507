import React from 'react'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import './CommunityNav.scss'

const CommunityNav = ({ community }) => {
    const { t } = useTranslation()

    return (
        <nav id="community-nav" class="level is-mobile fadeIn">
            <p class="level-item has-text-centered">
                <Link class="link is-info" to={`/community/${community.id}/feed`}>{t('home.nav.posts')}</Link>
            </p>
            <p class="level-item has-text-centered">
                <Link class="link is-info" to={`/community/${community.id}/calendar`}>{t('home.nav.calendar')}</Link>
            </p>
            <p class="level-item has-text-centered">
                <Link class="link is-info" to={`/community/${community.id}/directory`}>{t('home.nav.directory')}</Link>
            </p>
        </nav>
    )
}

export default CommunityNav