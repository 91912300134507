import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import './index.scss';
import * as serviceWorker from './serviceWorker';

import withAPI from './hoc/withAPI'
import './i18n';

import ErrorBoundary from './components/ErrorBoundary'
import Login from './pages/Login'
import Register from './pages/Register'
import Logout from './pages/Logout'
import Terms from './pages/Terms'
import CommunityHome from './pages/CommunityHome'
import AdminDashboard from './pages/AdminDashboard'
import Payment from './pages/Payment'
import UserSettings from './pages/UserSettings'
import ResetPassword from './pages/ResetPassword';
import UserProfile from './pages/UserProfile';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'bulma/css/bulma.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css'

Sentry.init({ dsn: "https://325c4f2d387b434faa8e982c90c27818@o158169.ingest.sentry.io/5205850" });

toast.configure()

//TODO error boundary
const App = () => (
    <ErrorBoundary>
        <BrowserRouter>
            <Route exact path="/" render={() => (<Redirect to="/login" />)} />
            <Route path="/community/:communityId" component={withAPI(CommunityHome)} />
            <Route exact path="/user/:userId" component={withAPI(UserProfile)} />
            <Route exact path="/login" component={withAPI(Login)} />
            <Route exact path="/reset-password" component={withAPI(ResetPassword)} />
            <Route path="/register" component={Register} />
            <Route path="/admin" component={withAPI(AdminDashboard)} />
            <Route path="/payment" component={withAPI(Payment)} />
            <Route path="/settings" component={withAPI(UserSettings)} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/logout" component={Logout} />
        </BrowserRouter>
    </ErrorBoundary>
)


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
