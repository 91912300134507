import React from 'react'
import { Link } from 'react-router-dom'
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';


const SideDrawer = ({ api, visible, showAdmin, toggleDrawer }) => {
    const { t } = useTranslation()

    async function activateCode(){
        const code = window.prompt(t('register.code_form.title'))
        if (code){
            const { error, ...result } = await api.activateCode(code)
            if (error){
                toast.error(`Code could not be activated: ${error}`)
            }
            else window.location.reload()
        }
    }

    return (
        <div>
            <Drawer
            title={t('sidebar.title')}
            keyboard={true}
            placement={'left'}
            closable={true}
            onClose={() => toggleDrawer()}
            visible={visible}>
                <div>
                    <div>
                        <Link to="/settings">{t('sidebar.profile')}</Link>
                        { !showAdmin &&  <hr /> }
                        { showAdmin && 
                            <>
                                <hr/>
                                    <Link to="/admin">{t('sidebar.admin')}</Link>
                                <hr/>
                            </> 
                        }
                        <a onClick={() => activateCode()}>
                            {t('sidebar.activate_code')}
                        </a>
                        <hr />
                        <Link to="/logout">
                            {t('sidebar.logout')}
                        </Link>
                        <hr />

                        {/* <a>
                            Report an issue
                        </a> */}
                    </div>
                </div>
            </Drawer>
      </div>
    )
}

export default SideDrawer