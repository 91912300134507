import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { loadStripe } from "@stripe/stripe-js";

import 'bulma-pricingtable/dist/css/bulma-pricingtable.min.css'
import './PaymentModal.scss'

const PaymentModal = ({ api, isActive }) => {
    const [prices, setPrices] = useState([])

    const [showModal, setShowModal] = useState(isActive)
    const { t, i18n } = useTranslation()

    useEffect(() => {
        async function getPrices() {
            const { data: prices } = await api.getPrices()
            console.log(prices)
            setPrices(prices)
        }

        getPrices()
    }, [])

    const redirectToCheckout = async (priceId) => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

        const { sessionId } = await api.createCheckoutSession(priceId, window.location.origin)
        stripe.redirectToCheckout({ sessionId })
            .then((result) => {
                if (result.error) alert(result.error.message)
            })
    }

    return (
        <div class={`modal ${showModal ? 'is-active' : ''}`}>
            <div class="modal-background">
                <div class="modal-card fadeInDown" style={{ marginTop: '1rem' }}>
                    <header class="modal-card-head">
                        <p class="modal-card-title">{t('payment.free_trial_expired.title')}</p>
                    </header>
                    <section class="modal-card-body">
                        <p class="modal-text">{t('payment.free_trial_expired.info')}</p>
                        <div class="price-grid">
                            {
                                prices.map(p => <PriceCard price={p} onClick={() => redirectToCheckout(p.id)} />)
                            }
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const PriceCard = ({ price, onClick }) => {
    const { recurring, unit_amount } = price
    const { interval } = recurring
    return (
        <div class="pricing-table">
            <div class="pricing-plan is-info">
                <div class="plan-header">{`${interval}ly`}</div>
                <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>{unit_amount / 100}</span>/{interval}</div>
                <div class="plan-items">
                </div>
                <div class="plan-footer">
                    <button class="button is-fullwidth" onClick={onClick}>Current plan</button>
                </div>
            </div>
        </div>
    )
}

export default PaymentModal