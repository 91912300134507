import React from 'react'
import { Link, Route, Redirect, Switch } from 'react-router-dom'
import LocaleToggle from '../../components/LocaleToggle'
import InviteCodeForm from './InviteCodeForm'
import UserForm from './UserForm'
import withAPI from '../../hoc/withAPI'
import { useTranslation } from 'react-i18next';


import './Register.scss'

const Register = () => {

    const { t, i18n } = useTranslation()

    return (
        <div class="bg-grey">
            <Switch>
                <Route exact path="/register" render={() => (<Redirect to="/register/code" />)} />
                <Route path="/register/code" component={withAPI(InviteCodeForm)} />
                <Route path="/register/user" component={withAPI(UserForm)} />
            </Switch>
            <Link class="log-in fadeInDown" to="/login">{t('register.login_link')}</Link>
            <LocaleToggle />
        </div>
    )
}

export default Register