import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Navbar from '../../components/Navbar'
import { formatPhoneNumber } from 'react-phone-number-input'
import { Avatar, List, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './UserProfile.scss'

const UserProfile = ({ api, match }) => {
    const [user, setUser] = useState({})
    const [me, setMe] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation()

    useEffect(() => {
        async function getUser() {
            const userId = match.params.userId
            const user = await api.getUser(userId)
            const me = await api.me()
            setUser(user)
            setMe(me)
            setIsLoading(false)
        }

        getUser()
    }, [])

    const formatBirthday = (birthdate) => {
        const birthdateObj = new Date(birthdate)
        birthdateObj.setDate(new Date(birthdate).getDate() + 1)
        return birthdateObj
    }

    return (
        <>
            { !isLoading &&
                <>
                    <Navbar api={api} user={me} />
                    <div class="profileContainer">
                        <div class="profileBanner fadeInDown">
                            <Avatar shape="circle" size={window.screen.width * 0.1} src={user.avatar} icon={<UserOutlined />} />
                            <div class="avatarInfo">
                                <p class="userName">{`${user.firstName} ${user.lastName}`}</p>
                                {user.location && <p><i class="fas fa-map-marker-alt" />{`${user.location}`}</p>}
                                {user.phoneNumber && <p><i class="fas fa-phone-alt" />{`${formatPhoneNumber(user.phoneNumber)}`}</p>}
                                {user.email && <p><i class="fas fa-envelope" />{`${user.email}`}</p>}
                            </div>
                        </div>
                        <div class="columns">

                            <div class="column userInfo fadeIn">
                                <div class="container box">

                                    <div class="field">
                                        <label class="label">{t('user_profile.birthdate')}</label>
                                        <div class="control">
                                            <p>{formatBirthday(user.birthdate).toLocaleString('default', { month: 'long', day: 'numeric' })}</p>
                                        </div>
                                    </div>

                                    <div class="field">
                                        <label class="label">{t('user_profile.profession')}</label>
                                        <div class="control">
                                            <p>{user.profession}</p>
                                        </div>
                                    </div>

                                    <div class="field">
                                        <label class="label">{t('user_profile.job_anniversary')}</label>
                                        <div class="control">
                                            <p>{user.jobAnniversary}</p>
                                        </div>
                                    </div>

                                    {/* <div class="field">
                                        <label class="label">{t('user_profile.baptism_date')}</label>
                                        <div class="control">
                                            <p>{user.baptismDate}</p>
                                        </div>
                                    </div> */}

                                    {/* <div class="field">
                                        <label class="label">{t('user_profile.sabbath_school_class')}</label>
                                        <div class="control">
                                            <p>{user.sabbathSchoolClass}</p>
                                        </div>
                                    </div> */}

                                    {/* <div class="field">
                                        <label class="label">{t('user_profile.willing_to_have_study_groups')}</label>
                                        <div class="control">
                                            <p>{user.willingToHaveStudyGroups === "Yes" ? t('user_settings.willing_to_have_study_groups_yes') : t('user_settings.willing_to_have_study_groups_no')}</p>
                                        </div>
                                    </div> */}
                                </div>
                                <div class="card-grid">
                                    {
                                        user.hobbies &&
                                        <div class="box">
                                            <h3 class="is-size-4">{t('user_profile.hobbies')}</h3>
                                            <ul style={{ height: 'unset', listStyle: 'disc' }}>
                                                {user.hobbies.map(item => <li>{item}</li>)}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        user.providedServices &&
                                        <div class="box">
                                            <h3 class="is-size-4">{t('user_profile.provided_services')}</h3>
                                            <ul style={{ height: 'unset', listStyle: 'disc' }}>
                                                {user.providedServices.map(item => <li>{item}</li>)}
                                            </ul>
                                        </div>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default UserProfile