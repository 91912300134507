import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInputChange } from '../../../../hooks'
import { Transfer } from 'antd';

import './CommunityAdmin.scss'

const CommunityAdmin = ({ api, community }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [banner, setBanner] = useState(community.banner)
    const [communityAdmins, setcommunityAdmins] = useState(community.communityAdmins)
    const [communityUsers, setCommunityUsers] = useState([])
    const [input, handleInputChange] = useInputChange()
    const [error, setError] = useState(null)
    const { t } = useTranslation()


    useEffect(() => {
        async function getCommunityUsers() {
            const users = await api.getUsers(community.id)
            const transferUsers = users.map(u => {
                u.key = u.id;
                return u;
            });
            community.users = transferUsers
            setCommunityUsers(transferUsers)
            setIsLoading(false)
        }

        getCommunityUsers()
    }, [community])

    const handleBannerChange = (e) => {
        const { files } = e.target
        if (files[0]) {
            input.banner = files[0]
            const fr = new FileReader();
            fr.onload = function () {
                setBanner(fr.result)
            }
            fr.readAsDataURL(files[0]);
        }
    }

    const saveCommunity = async () => {
        setIsLoading(true)
        try {
            const { error, ...result } = await api.updateCommunity(community.id, input)
            if (error) {
                setError(error)
            }
        }
        catch (err) {
            console.error(err)
            setError(err.message)
        }
        setIsLoading(false)
    }

    const onTransferChange = async (movedUsers) => {
        setIsLoading(true)
        try {
            const { error, ...result } = await api.updateCommunity(community.id, { communityAdmins: movedUsers.filter(x => x != "") })
            if (error) {
                setError(error)
            }
            else setcommunityAdmins(movedUsers)
        }
        catch (err) {
            console.error(err)
            setError(err.message)
        }
        setIsLoading(false)
    }

    return (
        <fieldset class="root" disabled={isLoading}>
            <div class="field">
                <label class="label">{t('community_admin.name')}</label>
                <div class="control">
                    <input class="input" type="text" name="name" defaultValue={community.name} onChange={handleInputChange} />
                </div>
            </div>

            <div class="field">
                <label class="label">{t('community_admin.address')}</label>
                <div class="control">
                    <input class="input" type="text" name="address" defaultValue={community.address} onChange={handleInputChange} />
                </div>
            </div>

            <div class="field">
                <label class="label">{t('community_admin.description')}</label>
                <div class="control">
                    <textarea class="textarea" name="description" defaultValue={community.description} onChange={handleInputChange}></textarea>
                </div>
            </div>

            <div class="field social-fields">
                <label class="label">{t('community_admin.social_media')}</label>
                <div class="control">
                    <i class="fab fa-facebook-square fa-2x"></i>
                    <input class="input" type="text" name="facebook" placeholder="Facebook" defaultValue={community.facebook} onChange={handleInputChange} />
                </div>
                <div class="control">
                    <i class="fab fa-twitter-square fa-2x"></i>
                    <input class="input" type="text" name="twitter" placeholder="Twitter" defaultValue={community.twitter} onChange={handleInputChange} />
                </div>
                <div class="control">
                    <i class="fab fa-instagram-square fa-2x"></i>
                    <input class="input" type="text" name="instagram" placeholder="Instagram" defaultValue={community.instagram} onChange={handleInputChange} />
                </div>
            </div>

            <div class="box communityAdmins">
                <label class="label">{t('community_admin.add_remove_admin_title')}</label>
                <p class="help">{t('community_admin.add_remove_admin_desc')}</p>
                <br />
                <Transfer
                    dataSource={communityUsers}
                    showSearch
                    showSelectAll={false}
                    listStyle={{
                        width: 250,
                        height: 300,
                    }}
                    targetKeys={communityAdmins}
                    onChange={onTransferChange}
                    render={item => `${item.firstName} ${item.lastName}`}
                />
            </div>


            <div class="field">
                <label class="label">{t('community_admin.community_banner')}</label>
                {banner ? <img class="bannerImage" src={banner} /> : <p>{t('community_admin.no_banner_found')}</p>}
                <div class="file has-name">
                    <label class="file-label">
                        <input class="file-input" type="file" name="banner" onChange={handleBannerChange} />
                        <span class="file-cta">
                            <span class="file-icon">
                                <i class="fas fa-upload"></i>
                            </span>
                            <span class="file-label">
                                {t('community_admin.choose_file')}
                            </span>
                        </span>
                    </label>
                </div>
            </div>

            { error && <p class="has-text-danger">{error}</p>}

            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-success" onClick={saveCommunity}>{t('community_admin.save')}</button>
                </div>
            </div>

        </fieldset>
    )
}

export default CommunityAdmin