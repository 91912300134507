import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/browser';

import Loader from '../../components/Loader'
import Navbar from '../../components/Navbar'
import CommunityBanner from '../../components/CommunityBanner'
import CommunityCalendar from '../../components/CommunityCalendar'
import UserDirectory from '../../components/UserDirectory'
import CommunityNav from './components/CommunityNav'
import CommunityAdmin from './components/CommunityAdmin'
import UserFeed from './components/UserFeed'

import { useTranslation } from 'react-i18next'

import './CommunityHome.scss'

const CommunityHome = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [state, setState] = useState({})
    const { t } = useTranslation()

    useEffect(() => {
        async function getProfile() {
            setIsLoading(true)
            const user = await props.api.me()

            Sentry.configureScope(function (scope) {
                scope.setUser({ email: user.profile.email, id: user.user_id, user });
            });

            if (user.communities.length === 0) {
                setState({ ...state, user, noCommunities: true })
            }
            else {
                let currentCommunity = user.communities[0]

                const communityId = props.match.params ? props.match.params.communityId : null;
                if (communityId == 1) {
                    props.history.push(`/community/${currentCommunity.id}/feed`)
                    return
                }

                if (communityId) {
                    try {
                        currentCommunity = await props.api.getCommunity(communityId)
                        if (currentCommunity.error) throw new Error(currentCommunity.error)
                    }
                    catch (err) {
                        console.error(err)
                    }
                }
                const canEditCommunity = currentCommunity.communityAdmins.includes(user.user_id) || user.roles.includes('developer')
                setState({ ...state, user, currentCommunity, canEditCommunity })
            }

            setIsLoading(false)
        }

        getProfile() //.catch( err => { if (err === 401) props.history.push('/login') });
    }, [props.match.params.communityId])


    if (!localStorage.getItem('jwt')) {
        props.history.push('/login')
    }

    return (
        <div id="home">
            <Navbar api={props.api} user={state.user} />
            {
                !isLoading && !state.noCommunities ?
                    <>
                        <CommunityBanner community={state.currentCommunity} canEditCommunity={state.canEditCommunity} />
                        <CommunityNav community={state.currentCommunity} />

                        <Switch>
                            <Route exact path="/community/:communityId/feed" component={() => <UserFeed api={props.api} community={state.currentCommunity} currentUser={state.user} />} />
                            <Route exact path="/community/:communityId/calendar" component={() => <CommunityCalendar api={props.api} user={state.user} community={state.currentCommunity} />} />
                            <Route exact path="/community/:communityId/directory" component={() => <UserDirectory api={props.api} community={state.currentCommunity} isAdmin={state.canEditCommunity} />} />
                            <Route exact path="/community/:communityId/admin" component={() => <CommunityAdmin api={props.api} community={state.currentCommunity} />} />
                        </Switch>
                    </>
                    : <Loader />
            }
            {
                state.noCommunities &&
                <div class="modal fadeInDown is-active" style={{ zIndex: 1 }}>
                    <div class="modal-card">
                        <header class="modal-card-head">
                            <p class="modal-card-title">{t('home.no_communities.title')}</p>
                        </header>
                        <section class="modal-card-body">
                            <p>{t('home.no_communities.description')}</p>
                        </section>
                    </div>
                </div>
            }

        </div>
    )
}

export default CommunityHome