import React from 'react'
import { useTranslation } from 'react-i18next'
import './CommunityInfo.scss'


const CommunityInfo = ({ community }) => {
    const { t } = useTranslation()
    return (
        <div class="card fadeInBottom communityInfo">
            <div class="card-border"></div>

            <div class="card-content">
                <h4>{t('home.info.description')}</h4>
                <p>{community.description}</p>

                <h4>{t('home.info.address')}</h4>
                <p>{community.address}</p>

                <div class="field is-grouped">
                    {
                        community.twitter &&
                        <p class="control">
                            <a href={community.twitter} target="_blank">
                                <button class="button">
                                    <span class="icon">
                                        <i class="fab fa-twitter"></i>
                                    </span>
                                </button>
                            </a>
                        </p>
                    }
                    {
                        community.instagram &&
                        <p class="control">
                            <a href={community.instagram} target="_blank">
                                <button class="button">
                                    <span class="icon">
                                        <i class="fab fa-instagram"></i>
                                    </span>
                                </button>
                            </a>
                        </p>
                    }
                    {
                        community.facebook &&
                        <p class="control">
                            <a href={community.facebook} target="_blank">
                                <button class="button">
                                    <span class="icon">
                                        <i class="fab fa-facebook"></i>
                                    </span>
                                </button>
                            </a>
                        </p>
                    }
                </div>
            </div>
        </div>
    );
}

export default CommunityInfo