import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import SideDrawer from '../../components/SideDrawer'
import PaymentModal from '../../components/PaymentModal'
import LocaleToggle from '../../components/LocaleToggle'
import { useTranslation } from 'react-i18next'

import './Navbar.scss'

const Navbar = ({ api, user }) => {
    const isAdmin = user && user.roles.includes('developer')
    const [showMenu, setMenu] = useState(false)
    const [allCommunities, setAllCommunities] = useState([])
    const { t } = useTranslation()

    const showPaymentModal = user && new Date(user.subscription.endDate).getTime() < new Date().getTime() && window.location.pathname !== "/settings"

    useEffect(() => {
        async function getAllCommunities() {
            const communities = await api.getCommunities()
            setAllCommunities(communities)
        }

        getAllCommunities()
    }, [])

    return (
        <>
            <SideDrawer api={api} toggleDrawer={() => setMenu(!showMenu)} visible={showMenu} showAdmin={isAdmin} />
            <PaymentModal api={api} isActive={showPaymentModal} />

            <nav id="nav" class="navbar">
                <div class="navbar-brand">
                    <Link class="navbar-item" to="/community/1">
                        <img src="/logo.jpg" style={{ maxHeight: "5.75rem" }} />
                        <h1 class="is-size-3">Listing4Us</h1>
                    </Link>

                    <a role="button" class="navbar-burger burger" onClick={() => setMenu(!showMenu)} aria-label="menu" aria-expanded="false">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                {
                    user &&
                    <div class="navbar-menu">

                        {
                            user.communities.length > 0 &&
                            <div class="navbar-item">
                                <div class="dropdown is-hoverable">
                                    <div class="dropdown-trigger">
                                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                            <span>{t('navbar.community_select')}</span>
                                            <span class="icon is-small">
                                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                        </button>
                                    </div>
                                    <div class="dropdown-menu" role="menu">
                                        <div class="dropdown-content">
                                            {
                                                isAdmin ? allCommunities.map(community => <Link to={{ pathname: `/community/${community.id}/feed`, state: { communityId: community.id } }} class="dropdown-item">{community.name}</Link>)
                                                    : user.communities.map(community => <Link to={{ pathname: `/community/${community.id}/feed`, state: { communityId: community.id } }} class="dropdown-item">{community.name}</Link>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div class="navbar-item">
                            <LocaleToggle />
                        </div>

                        {/* <div class="navbar-end">
                            <div class="navbar-item has-dropdown is-hoverable" onClick={() => setMenu(!showMenu)} style={{}}>
                                <a class="navbar-link">
                                    honk
                                </a>
                            </div>
                        </div> */}

                        <div class="navbar-end">
                            <div class="navbar-item has-dropdown is-hoverable" onClick={() => setMenu(!showMenu)} style={{ marginRight: '3rem' }}>
                                <a class="navbar-link">
                                    <Avatar shape="circle" size={27} src={user.profile.avatar} icon={<UserOutlined />} />
                                </a>
                            </div>
                        </div>

                    </div>
                }

            </nav>
        </>
    )
}

export default Navbar