import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import AdminSidebar from './components/AdminSidebar';
import AdminMetrics from './components/AdminMetrics';
import AdminCodes from './components/AdminCodes';
import AdminCommunities from './components/AdminCommunities';
import Navbar from '../../components/Navbar';

import './AdminDashboard.scss';
import AdminSubscriptions from './components/AdminSubscriptions';

const AdminDashboard = (props) => {
    const [state, setState] = useState({ collapsed: false })

    useEffect(() => {
        async function getUser() {
            const user = await props.api.me()
            if (!user.roles.includes('developer')) props.history.push('/')
            setState({ user })
        }

        getUser()
    }, [])

    function toggleCollapsed() {
        setState({
            collapsed: !state.collapsed
        })
    }

    return (
        <>
            <Navbar api={props.api} user={state.user} />
            <div class="adminContainer">
                <AdminSidebar collapsed={state.collapsed} toggleCollapsed={toggleCollapsed} />
                <Switch>
                    <Route exact path="/admin" component={() => <AdminMetrics api={props.api} />} />
                    <Route exact path="/admin/codes" component={() => <AdminCodes api={props.api} />} />
                    <Route exact path="/admin/communities" component={() => <AdminCommunities api={props.api} />} />
                    <Route exact path="/admin/subscriptions" component={() => <AdminSubscriptions api={props.api} />} />
                </Switch>
            </div>
        </>
    )
}

export default AdminDashboard