import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useInputChange } from '../../../../hooks';
import './AdminCodes.scss';

const AdminCodes = ({ api }) => {
    const [communities, setCommunities] = useState([]);
    const [codes, setCodes] = useState([]);
    const [input, handleInputChange] = useInputChange({ count: 1 });

    useEffect(() => {
        async function getCommunities() {
            const communities = await api.getCommunities();
            if (communities.length > 0) input.communityId = communities[0].id
            setCommunities(communities);
        }

        getCommunities();
    }, [])

    async function generateCodes() {
        const { communityId, count } = input;
        try {
            const codes = await api.makeCodes(Number(communityId), count);

            if (codes.error) {
                console.error(codes.error)
                toast.error(codes.error)
            }
            else {
                setCodes(codes)
            }
        }
        catch (err) {
            console.error(err)
            toast.error(err)
        }
    }

    function formatCodes(codes) {
        return codes.map(c => c.id).join('\n')
    }

    return (
        <section class="codesContainer">
            <h1 class="title is-1">Create Invite Codes</h1>

            <div class="content">
                <p>Select a community to generate invite codes</p>
            </div>


            <div class="field">
                <label class="label">Community</label>
                <div class="control">
                    <div class="select">
                        <select name="communityId" onChange={handleInputChange}>
                            {communities.map(c => {
                                return <option value={c.id}>{c.name}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div class="field countInput">
                <label class="label">Quantity</label>
                <div class="control">
                    <input class="input" type="number" name="count" min={1} defaultValue={1} onChange={handleInputChange} />
                </div>
            </div>

            <pre class="codeList">
                {formatCodes(codes)}
            </pre>

            <div class="field">
                <div class="control">
                    <button class="button is-primary" onClick={generateCodes}>
                        Get Codes
                    </button>
                </div>
            </div>


        </section>
    )
}

export default AdminCodes;