import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { Table } from 'antd'
import { toast } from 'react-toastify'

import './AdminSubscriptions.scss'

const AdminSubscriptions = ({ api }) => {
    const [subscriptions, setSubscriptions] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [currentSubscription, setCurrentSubscription] = useState({ endDate: new Date() })

    useEffect(() => {
        getSubscriptions()
    }, [])

    async function getSubscriptions() {
        const subscriptions = await api.getSubscriptions();
        setSubscriptions(subscriptions)
    }

    async function handleSubscriptionClick(subscription) {
        setShowModal(true)
        setCurrentSubscription(subscription)
    }

    async function updateSubscription(subscriptionId, updateFields) {
        const { error, ...result } = await api.updateSubscription(subscriptionId, updateFields)
        if (error) {
            toast.error(`Error updating subscription ${error}`)
        }
        else {
            setShowModal(false)
            await getSubscriptions()
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'id',
            key: 'id',
            render: id => {
                const subscription = subscriptions.find(subscription => subscription.id === id)
                return (
                    <p>{`${subscription.user.firstName} ${subscription.user.lastName}`}</p>
                )
            }
        },
        {
            title: 'Subscription Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: 'Subscription End Date',
            dataIndex: 'endDate',
            key: 'endDate',
        },
        {
            title: 'Action',
            key: 'id',
            dataIndex: 'id',
            render: id => {
                const subscription = subscriptions.find(subscription => subscription.id === id)
                return (
                    <>
                        <button class="button is-success" onClick={() => handleSubscriptionClick(subscription)}><i class="fas fa-edit"></i>Edit</button>
                    </>
                )
            },
        },
    ];

    return (
        <section class="communitiesContainer">
            <h1 class="title is-1">Subscriptions</h1>
            <Table columns={columns} dataSource={subscriptions} />
            <SubscriptionModal isOpen={showModal} toggle={setShowModal} onSave={updateSubscription} subscription={currentSubscription} />
        </section>
    )
}

const SubscriptionModal = ({ isOpen, toggle, onSave, subscription }) => {
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        setEndDate(new Date(subscription.endDate));
    }, [subscription]);

    return (
        <div class={`modal ${isOpen && 'is-active'}`}>
            <div class="modal-background" onClick={() => toggle(!isOpen)}></div>
            <div class="modal-card fadeInDown" style={{ animationDuration: '1s' }}>
                <header class="modal-card-head">
                    <p class="modal-card-title"><i class="far fa-calendar-alt"></i>Edit Subscription</p>
                    <button class="delete" aria-label="close" onClick={() => toggle(!isOpen)}></button>
                </header>

                <section class="modal-card-body">
                    <fieldset disabled={false}>
                        <div class="field is-horizontal">
                            <div class="field-body">
                                {/* <div class="field">
                                    <label class="label">Start Date</label>
                                    <p class="control is-expanded">
                                        <DatePicker
                                            selected={subscription.startDate ? new Date(subscription.startDate) : new Date()}
                                            onChange={() => {}}
                                            selectsStart
                                            startDate={subscription.startDate}
                                            endDate={subscription.endDate}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            className="input" />                                        
                                    </p>
                                </div> */}
                                <div class="field">
                                    <label class="label">End Date</label>
                                    <p class="control is-expanded">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => { setEndDate(date) }}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            className="input"
                                           />       
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p>Changing the "End Date" will extend the user's access to the site</p>
                    </fieldset>
                </section>

                <footer class="modal-card-foot">
                    <button class="button is-success" onClick={() => onSave(subscription.id, { endDate: endDate.toISOString() }) }>Save</button>
                    <button class="button" onClick={() => toggle(false)}>Cancel</button>
                </footer>
            </div>
        </div>
    )
}


export default AdminSubscriptions;