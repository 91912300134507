import React from 'react'
import { Link } from 'react-router-dom'

import './CommunityBanner.scss'

const CommunityBanner = ({ community, canEditCommunity }) => {
    let imgURL = community.banner
    const communityName = community ? community.name : ''

    const bannerStyle = {
        backgroundImage: `linear-gradient(rgba(0, 47, 230, 0.5), rgba(39, 171, 131, 0)), url("${imgURL}")`,
        animationDuration: '3s'
    }
    return (
        <div class="community-banner fadeIn" style={bannerStyle}>
            <p class="is-size-1 is-size-2-mobile has-text-white fadeInDown" style={{ animationDuration: '3s' }}>
                {communityName}
                {canEditCommunity && <Link class="button" to={`/community/${community.id}/admin`}><i class="fas fa-pencil-alt" style={{ marginRight: '5px' }} /></Link>}
            </p>

        </div>
    )
}

export default CommunityBanner